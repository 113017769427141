import React from 'react';
import { Link } from 'gatsby';
import Layout from '@components/Layouts/Layout';
import { OneColumn } from '@components/Layouts/OneColumn';
import SEO from '@components/Layouts/SEO';
import styles from './404.module.scss';

function NotFoundPage() {
  return <Layout>
    <SEO title="Страница не найдена" description="Страница не найдена" />
    <OneColumn>
      <h1 className={styles.header}>Такой страницы нет</h1>
      <p className={styles.description}>
        Но у нас есть другие страницы, например –&nbsp;
        <Link className={styles.link} to="/">
          главная
        </Link>
        !
      </p>
    </OneColumn>
  </Layout>
}

export default NotFoundPage;
